import SessionsComponentClient from '../../components/sessions/clients/SessionsComponentClient';

function SessionsClientsPage() {
    return (
            <div className="sessions__container">
                <SessionsComponentClient />
            </div>
       
    )
}

export default SessionsClientsPage;