import './login.css';
import React, { useState } from "react";
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';

const LoginForm = () => {
    const [showCodeInput, setShowCodeInput] = useState(false);

    const [phone, setPhone] = useState(''); // phone
    const [code, setCode] = useState(''); //code

    const handleSubmitPhone = (e) => {
        e.preventDefault();

        setShowCodeInput(true);
    };

    const handlePhoneChange = (e) => {
       let phone = e.target.value.replace(/\D/g, '');

        setPhone(phone);
      };

    const handleSubmitCode = (e) => {
        e.preventDefault();

        setShowCodeInput(false);
    };
    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };
    return (
        <div className="login">
            <h1 className="login__title">Войдите в свой аккаунт</h1>
            <div className="login__row">
                {!showCodeInput ? (
                    <form className="login__form" onSubmit={handleSubmitPhone}>
                        <p className="login__paragraph">
                            Введите свой номер телефона.
                        </p>
                        <p className="login__paragraph">
                            Отправим SMS с проверочным кодом.
                        </p>
                        <div className="login__field">
                            <InputMask
                                mask="+7 (999) 999-99-99"
                                placeholder="+7 (___) ___-__-__"
                                className="login__input input"
                                type="tel"
                                id="phone"
                                required
                                value={phone}
                                onChange={handlePhoneChange}
                            />
                        </div>
                        <div className="login__actions">
                            <button type="submit" className="login__button button">Получить код</button>
                        </div>
                        <p className="login__paragraph">
                            Нет аккаунта? <Link to="/registration" className="login__link link">Регистрация</Link>
                        </p>
                    </form>
                ) : (
                    <form className="login__form" onSubmit={handleSubmitCode}>
                        <p className="login__paragraph">
                            Введите код из SMS-сообщения, отправленного на номер {phone}
                        </p>
                        <div className="login__field">
                            <input
                                className="login__input input"
                                type="text"
                                id="code"
                                required
                                value={code}
                                onChange={handleCodeChange}
                            />
                        </div>
                        <div className="login__actions">
                            <button type="submit" className="login__button button">Продолжить</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default LoginForm;