import { useState, ReactElement } from 'react';

import { BrowserRouter,Route, Routes, Navigate } from 'react-router-dom';

// Import components

import Footer from './components/footer/Footer.jsx';
import Header from './components/header/Header.jsx';
import Index from './components/index/Index.jsx';
import LoginForm from './components/login/LoginForm.jsx';
import { MenuContext } from './components/menu/MenuContext.jsx';
import QuestionaryStepForm from './components/questionary/QuestionaryStepForm.jsx';
import RegistrationForm from './components/registration/RegistrationForm.jsx';
import SessionsComponent from './components/sessions/SessionsComponent.jsx';
import Sidebar from './components/sidebar/Sidebar.jsx';
import NoPage from './pages/404Page.jsx'
import ClientsPage from './pages/profile/ClientsPage.jsx';
import EducationPage from './pages/profile/EducationPage.jsx';
import OrganizationsPage from './pages/profile/OrganizationsPage.jsx';
import OtherPage from './pages/profile/OtherPage.jsx';
import ProfilePage from './pages/profile/ProfilePage.jsx';
import SchedulePage from './pages/profile/SchedulePage.jsx';
import StatisticPage from './pages/profile/StatisticPage.jsx';
//client
import SessionsClientsPage from './pages/clients_profile/SessionsClientsPage.jsx';

function App(): ReactElement {
  const mmLogin = true;

  const [isLoggedIn] = useState<boolean>(mmLogin ? true : false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenuMobile = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <MenuContext.Provider value={{ isMenuOpen, toggleMenuMobile }}>
      <BrowserRouter>
      {console.log('Current route:', window.location.pathname)}

        <div className="wrapper">
          <Header />
          <main className="main">
            {isLoggedIn && <Sidebar />}
            <div className="main__content">
              <div className="main__container container">
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="login" element={<LoginForm />} />
                  <Route path="/registration">
                    <Route index element={<RegistrationForm />} />
                    <Route path="questionary" element={<QuestionaryStepForm />} />
                  </Route>
                  <Route
                    path="/sessions"
                    element={isLoggedIn ? <SessionsComponent /> : <Navigate to="/login" />}
                  />
                  <Route path="/profile">
                    <Route
                      index
                      element={isLoggedIn ? <ProfilePage /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="other"
                      element={isLoggedIn ? <OtherPage /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="organizations"
                      element={isLoggedIn ? <OrganizationsPage /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="education"
                      element={isLoggedIn ? <EducationPage /> : <Navigate to="/login" />}
                    />
                  </Route>
                  <Route
                    path="/schedule"
                    element={isLoggedIn ? <SchedulePage /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/clients"
                    element={isLoggedIn ? <ClientsPage /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/statistics"
                    element={isLoggedIn ? <StatisticPage /> : <Navigate to="/login" />}
                  />
                   <Route
                    path="/sessions_client"
                    element={isLoggedIn ? <SessionsClientsPage /> : <Navigate to="/login" />}
                  />
                   <Route
                    path="/*"
                    element={<NoPage/>}
                  />
                </Routes>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </BrowserRouter>
    </MenuContext.Provider>
  );
}

export default App;