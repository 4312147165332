import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Обновляем состояние, чтобы показать fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Можно записать ошибку в лог или отправить на сервер
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Можно отобразить кастомный fallback UI
      return <h2>Что-то пошло не так. Попробуйте позже.</h2>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
