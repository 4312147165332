import React, { useState } from "react";
import photoImg from '../../../public/img/photo.png';

const ProfilePage = () => {
    const [showEditPage, setShowEditPage] = useState(false);

    const [tempData, setTempData] = useState({
        firstName: '',
        lastName: '',
        middleName: '',
        dateBirth: '',
        email: '',
        link: '',
    });

    const [formData, setFormData] = useState({
        firstName: 'Иван',
        lastName: 'Иванов',
        middleName: 'Иванович',
        dateBirth: '2000-01-01',
        email: 'example@meetmind.ru',
        link: 'https://vk.com/ivanov',
    });

    const handleEditPage = (e) => {
        e.preventDefault();
        setShowEditPage(!showEditPage);

        if (!showEditPage) {
            // Инициализируем tempData при переходе в режим редактирования
            setTempData(formData);
        }
    };

    const sanitizeTextInput = (e) => {
        const { name, value } = e.target;

        // Обновляем tempData с удалением специальных символов
        setTempData((prevData) => ({
            ...prevData,
            [name]: value.replace(/[<>"'&]/g, ''),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData(tempData);
        setShowEditPage(false);
    };

    return (
        <div className="profile">
            {!showEditPage ? (
                <div className="profile__body">
                    <div className="profile__header">
                        <div className="profile__title">Профиль</div>
                        <div className="profile__controller" onClick={handleEditPage}>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div className="profile__row">
                            <div className="profile__column">
                                <div className="profile__photo">
                                    <img src={photoImg} alt="Person" />
                                </div>
                                <button className="profile__button button">Загрузить фото</button>
                            </div>
                            <div className="profile__info">
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Фамилия
                                        <input className="profile__input input" type="text" disabled value={formData.lastName} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Имя
                                        <input className="profile__input input" disabled value={formData.firstName} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Отчество
                                        <input className="profile__input input" disabled value={formData.middleName} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Дата рождения
                                        <input className="profile__input input" disabled value={formData.dateBirth} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Адрес электронной почты
                                        <input className="profile__input input" disabled value={formData.email} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Ссылка на профиль в социальных сетях
                                        <input className="profile__input input" disabled value={formData.link} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="profile__body">
                    <div className="profile__header">
                        <div className="profile__title">Профиль</div>
                        <div className="profile__controller" onClick={handleEditPage}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div className="profile__row">
                            <div className="profile__column">
                                <div className="profile__photo">
                                    <img src={photoImg} alt="Person" />
                                </div>
                                <button className="profile__button button">Загрузить фото</button>
                            </div>
                            <div className="profile__info">
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Фамилия *
                                        <input className="profile__input input" type="text" placeholder="Иванов" maxLength={25} name="lastName" value={tempData.lastName} onChange={sanitizeTextInput} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Имя *
                                        <input className="profile__input input" type="text" placeholder="Иван" maxLength={25} name="firstName" value={tempData.firstName} onChange={sanitizeTextInput} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Отчество
                                        <input className="profile__input input" type="text" placeholder="Иванович" maxLength={25} name="middleName" value={tempData.middleName} onChange={sanitizeTextInput} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Дата рождения *
                                        <input className="profile__input input" type="date" name="dateBirth" value={tempData.dateBirth} onChange={sanitizeTextInput} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Адрес электронной почты
                                        <input className="profile__input input" type="email" placeholder="example@meetmind.ru" maxLength={25} name="email" value={tempData.email} onChange={sanitizeTextInput} />
                                    </label>
                                </div>
                                <div className="profile__field">
                                    <label className="profile__label label">
                                        Ссылка на профиль в социальных сетях
                                        <input className="profile__input input" type="text" placeholder="https://vk.com/ivanov" maxLength={25} name="link" value={tempData.link} onChange={sanitizeTextInput} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile__actions">
                        <button className="profile__button button" onClick={handleSubmit}>Сохранить изменения</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfilePage;
