import CalendarSchedule from '../../components/calendar/Calendar';
import './calendarschedule.css'

function SchedulePage() {
    return (
        
            <div className="page__row center_elements">
                <CalendarSchedule />
            </div>
       
    )
}

export default SchedulePage;