import indexCafe from '../../../public/img/index_cafe.png'


import firstNumber from '../../../public/img/1.png'
import secondNumber from '../../../public/img/2.png'
import thirdNumber from '../../../public/img/3.png'
import fouthNumber from '../../../public/img/4.png'
import imageGraph from '../../../public/img/graph.png'

import qualityIcon from '../../../public/img/quality_icon.png'
import analyticImage from '../../../public/img/analytic_image.png'
import callImage from '../../../public/img/call_index.png'
import hideIcon from '../../../public/img/hide_icon.png'
import starsIcon from '../../../public/img/stars_icon.png'
import heartIcon from '../../../public/img/heart_icon.png'

import dots1 from '../../../public/img/dots1.png'
import dots2 from '../../../public/img/dots2.png'
import iconGroup from '../../../public/img/group_index.png'
import indexCalendar from '../../../public/img/index_calendar.png'
import indexConvers from '../../../public/img/index_convers.png'
import indexGraphics from '../../../public/img/index_graphics.png'
import indexPeople from '../../../public/img/index_people.png'
import iconPair from '../../../public/img/pair_index.png'
import iconUser from '../../../public/img/user_index.png'

const images = {
    indexCafe,
    indexCalendar,
    indexGraphics,
    indexConvers,
    firstNumber,
    secondNumber,
    thirdNumber,
    fouthNumber,
    callImage,
    iconUser,
    iconPair,
    iconGroup,
    imageGraph,
    qualityIcon,
    analyticImage,
    hideIcon,
    starsIcon,
    heartIcon,
    dots1,
    dots2,
    indexPeople,
  };

  export default images;