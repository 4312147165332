const ClientsStatistic = () => {
    return (
        <div className="background-white card-paddings full-width mr-20">
            <h4 className="mb-20">Клиенты</h4>
            <div className="scrollable-container">
                <table className="table-statistic full-width gray-stripes">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>ID</th>
                            <th>Имя</th>
                            <th>Количество сессий</th>
                            <th>Последняя сессия</th>
                            <th>Статус</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>#222</td>
                            <td>Анна</td>
                            <td>3</td>
                            <td>22.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>#223</td>
                            <td>Дмитрий</td>
                            <td>5</td>
                            <td>23.04.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>#224</td>
                            <td>Мария</td>
                            <td>2</td>
                            <td>24.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>#225</td>
                            <td>Сергей</td>
                            <td>7</td>
                            <td>25.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>#226</td>
                            <td>Елена</td>
                            <td>4</td>
                            <td>26.04.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>#227</td>
                            <td>Александр</td>
                            <td>10</td>
                            <td>27.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>#228</td>
                            <td>Наталья</td>
                            <td>8</td>
                            <td>28.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>#227</td>
                            <td>Александр</td>
                            <td>10</td>
                            <td>27.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>#228</td>
                            <td>Наталья</td>
                            <td>8</td>
                            <td>28.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>#229</td>
                            <td>Иван</td>
                            <td>6</td>
                            <td>29.04.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>#230</td>
                            <td>Ольга</td>
                            <td>3</td>
                            <td>30.04.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>#231</td>
                            <td>Петр</td>
                            <td>9</td>
                            <td>01.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>#232</td>
                            <td>Виктория</td>
                            <td>7</td>
                            <td>02.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>#233</td>
                            <td>Михаил</td>
                            <td>5</td>
                            <td>03.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>#234</td>
                            <td>Анастасия</td>
                            <td>3</td>
                            <td>04.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>#235</td>
                            <td>Константин</td>
                            <td>8</td>
                            <td>05.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>#236</td>
                            <td>Евгения</td>
                            <td>6</td>
                            <td>06.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>#237</td>
                            <td>Артем</td>
                            <td>4</td>
                            <td>07.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>17</td>
                            <td>#238</td>
                            <td>Людмила</td>
                            <td>9</td>
                            <td>08.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>18</td>
                            <td>#239</td>
                            <td>Артур</td>
                            <td>2</td>
                            <td>09.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>#240</td>
                            <td>Валентина</td>
                            <td>5</td>
                            <td>10.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>20</td>
                            <td>#241</td>
                            <td>Георгий</td>
                            <td>7</td>
                            <td>11.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>21</td>
                            <td>#242</td>
                            <td>Вера</td>
                            <td>4</td>
                            <td>12.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>22</td>
                            <td>#243</td>
                            <td>Денис</td>
                            <td>8</td>
                            <td>13.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>23</td>
                            <td>#244</td>
                            <td>Ирина</td>
                            <td>6</td>
                            <td>14.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>24</td>
                            <td>#245</td>
                            <td>Роман</td>
                            <td>3</td>
                            <td>15.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td>#246</td>
                            <td>София</td>
                            <td>7</td>
                            <td>16.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>26</td>
                            <td>#247</td>
                            <td>Павел</td>
                            <td>5</td>
                            <td>17.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>27</td>
                            <td>#248</td>
                            <td>Юлия</td>
                            <td>2</td>
                            <td>18.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>28</td>
                            <td>#249</td>
                            <td>Никита</td>
                            <td>9</td>
                            <td>19.05.2024</td>
                            <td>Приостановлено</td>
                        </tr>
                        <tr>
                            <td>29</td>
                            <td>#250</td>
                            <td>Екатерина</td>
                            <td>4</td>
                            <td>20.05.2024</td>
                            <td>Активно</td>
                        </tr>
                        <tr>
                            <td>30</td>
                            <td>#251</td>
                            <td>Алексей</td>
                            <td>6</td>
                            <td>21.05.2024</td>
                            <td>Активно</td>
                        </tr>
                    </tbody>
                </table>
            </div></div>
    );
};

export default ClientsStatistic;