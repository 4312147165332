import React, { useState } from 'react';

import { JazzContainer } from '../../../pages/profile/JazzContainer';
import ErrorBoundary from '../../error/componentDidCatch';

import SessionsListPsy from "./SessionsListPsy";

const SessionsComponentClient = (props) => {
    const [activeClient, setActiveClient] = useState(1);
    return (
        <ErrorBoundary>
        <div>
           <div className="flex-row">
                <SessionsListPsy clients={clients} activeClient={activeClient} setActiveClient={setActiveClient} />
                <div className="flex-column sessions__screen">
               <JazzContainer/>
              
                </div>
            </div>
        </div></ErrorBoundary>
    );
};

export default SessionsComponentClient;

const clients = [
    {
        id: 1,
        name: "Иван Иванов",
        dateOfSession: "2024-03-15 15:00",
        typeOfSession: 1,
    },
    {
        id: 2,
        name: "Мария Смирнова",
        dateOfSession: "2024-03-15",
        typeOfSession: 2,
    },
    {
        id: 3,
        name: "Алексей Петров",
        dateOfSession: "2024-03-15",
        typeOfSession: 3,
    },
   
];
