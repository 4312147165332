import { FC } from 'react';

export const HandIcon: FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M.95 6.67c.69-.35 1.27.12 1.7.59.28.32.52.68.76 1.03l.37.52c.1.14.3.51.53.37.16-.09.15-.35.14-.5-.02-.5-.03-.98-.09-1.46-.1-.86-.26-1.7-.41-2.56l-.11-.57c-.11-.6-.21-1.22-.28-1.83-.04-.37.1-.92.49-1.04.6-.17.94.2 1.11.62a27.23 27.23 0 01.64 2.83c.24 1.41.25 1.2.48 2.6.01.11.11.2.23.19a.2.2 0 00.14-.05.2.2 0 00.07-.15l-.12-4.3A41.6 41.6 0 016.58.9c0-.42.26-.82.7-.89.54-.08.98.25 1.03.79.19 2.15.16 4.3.34 6.46 0 .06.03.11.07.15.13.1.35.05.35-.13.2-1.95.41-3.91.68-5.85.07-.5.43-.78.93-.73.53.06.76.48.74.97-.02.72-.1 1.45-.17 2.15a36.7 36.7 0 00-.23 3.53c0 .28.42.23.47-.01l.42-2.13c.13-.64.28-1.36.46-1.96.14-.46.67-.82 1.21-.6.42.16.48.8.37 1.42l-.2 1.04a40.22 40.22 0 00-.44 3.1v.11a31.2 31.2 0 00-.12 2.33c.08 2.82-1.24 5.2-4.25 5.34-2.01.1-2.82-.59-4.16-1.74-1.18-1-1.5-2.36-2.31-3.55-.34-.49-.56-1.33-.78-1.74-.31-.55-.47-.73-.77-1.16l-.03-.03c-.22-.36-.4-.87.06-1.1z"
    />
  </svg>
);
