const SessionsListPsyCard = ({ id, name, dateOfSession, typeOfSession, activeClient, setActiveClient }) => {
    let dataSession;
    if (typeOfSession == 1) {
        dataSession = <p className="mr-10 sessions__grey-text">Личная терапия</p>
    } else if (typeOfSession == 2) {
        dataSession = <p className="mr-10 sessions__grey-text">Парная терапия</p>
    } else if (typeOfSession == 3) {
        dataSession = <p className="mr-10 sessions__grey-text">Групповая терапия</p>
    }
    else {
        dataSession = <p className="mr-10 sessions__grey-text">Нет данных</p>
    }
    return (
        <div className="flex-column sessions_list-elements" onClick={() => setActiveClient(id)}>
            <div><p className="space-nowrap  mr-10">{name}</p></div>
            <div className="flex-column"><p className="sessions__grey-text full-width">{dateOfSession}</p>
            <div className="full-width">
                {dataSession}
                </div></div>
        </div>
    );
};

export default SessionsListPsyCard
