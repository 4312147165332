import React, { useState } from "react";

const OtherPage = () => {
    const [showEditPage, setShowEditPage] = useState(false);
    const [tempData, setTempData] = useState('');
    const [formData, setFormData] = useState(''); // Здесь вы можете задать начальное значение, если нужно

    const handleEditPage = (e) => {
        e.preventDefault();
        if (!showEditPage) {
            setTempData(formData); // Инициализируем tempData при переходе в режим редактирования
        }
        setShowEditPage(!showEditPage);
    };

    const sanitizeTextInput = (e) => {
        const value = e.target.value.replace(/[<>"'&]/g, ''); // Убираем специальные символы
        setTempData(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData(tempData);
        setShowEditPage(false);
    };

    const handleCancel = () => {
        setTempData(formData); // Возврат к исходным данным
        setShowEditPage(false);
    };

    return (
        <div className="profile">
            {!showEditPage ? (
                <div className="profile__body">
                    <div className="profile__header">
                        <div className="profile__title">Прочее</div>
                        <div className="profile__controller" onClick={handleEditPage}>
                            <i className="fa-solid fa-pen-to-square" onClick={handleCancel}></i>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div className="profile__field">
                            <label className="profile__label label">
                                Расскажите о себе в свободной форме, что Вы считаете нужным чтобы понять, какой Вы специалист
                                <textarea className="profile__textarea textarea" rows="5" disabled value={formData}></textarea>
                            </label>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="profile__body">
                    <div className="profile__header">
                        <div className="profile__title">Прочее</div>
                        <div className="profile__controller" onClick={handleEditPage}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div className="profile__field">
                            <label className="profile__label label">
                                Расскажите о себе в свободной форме, что Вы считаете нужным чтобы понять, какой Вы специалист
                                <textarea
                                    className="profile__textarea textarea"
                                    rows="5"
                                    value={tempData}
                                    onChange={sanitizeTextInput}
                                    maxLength={300}
                                ></textarea>
                            </label>
                        </div>
                    </div>
                    <div className="profile__actions">
                        <button className="profile__button button" onClick={handleSubmit}>Сохранить изменения</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OtherPage;
