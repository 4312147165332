import './clients.css'
import React, { useState } from 'react';

import ClientsTable from '../../components/clients/ClientsTable';

function ClientsPage() {
    const [dataPersonTherapy, setDataPerson] = useState([
        {
            id: 1,
            userId: 'user123',
            name: 'Иван Иванов',
            sessionCount: 5,
            lastSession: '2024-06-15',
            status: 1,
            groupId: 1,
        },
        {
            id: 2,
            userId: 'user456',
            name: 'Петр Петров',
            sessionCount: 10,
            lastSession: '2024-06-14',
            status: 2,
            groupId: null,
        },
        // Здесь остальные данные...
    ]);

    const [dataPairTherapy, setDataPair] = useState([
        {
            id: 1,
            userId: 'user123',
            name: 'Иван',
            sessionCount: 5,
            lastSession: '2024-06-15',
            status: 1,
            groupId: 1,
        },
        {
            id: 2,
            userId: 'user456',
            name: 'Петр',
            sessionCount: 10,
            lastSession: '2024-06-14',
            status: 2,
            groupId: null,
        },
        // Здесь остальные данные...
    ]);

    const [dataGroupTherapy, setDataGroup] = useState([
        {
            id: 1,
            userId: 'user123',
            name: 'Иван Иванов',
            sessionCount: 5,
            lastSession: '2024-06-15',
            status: 1,
            groupId: 1,
        },
        {
            id: 2,
            userId: 'user456',
            name: 'Петр Петров',
            sessionCount: 10,
            lastSession: '2024-06-14',
            status: 2,
            groupId: null,
        },
        // Здесь остальные данные...
    ]);
    return (
        <div className="page__content">
            <ClientsTable className="mb-1" data={dataPersonTherapy} title="Личная терапия" type={1} />
            <ClientsTable className="mb-1" data={dataPairTherapy} title="Парная терапия" type={2} />
            <ClientsTable data={dataGroupTherapy} title="Групповая терапия" type={3}/>
        </div>
    )
}
export default ClientsPage;