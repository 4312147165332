
function NoPage() {
    return (
            <div style={{textAlign: "center", height: "100%"}}>
                <h2>Страница не найдена</h2>
            </div>
       
    )
}

export default NoPage;