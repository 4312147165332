import React, { useState } from "react";

const EducationPage = () => {
    const [showEditPage, setShowEditPage] = useState(false);
    const [tempData, setTempData] = useState({
        mainEducation: '',
        additionalEducation: '',
    });
    const [formData, setFormData] = useState({
        mainEducation: '',
        additionalEducation: '',
    });

    const handleEditPage = (e) => {
        e.preventDefault();
        if (!showEditPage) {
            // Инициализируем tempData при переходе в режим редактирования
            setTempData(formData);
        }
        setShowEditPage(!showEditPage);
    };

    const sanitizeTextInput = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/[<>"'&]/g, ''); // Убираем специальные символы
        setTempData((prevData) => ({
            ...prevData,
            [name]: sanitizedValue,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData(tempData);
        setShowEditPage(false);
    };

    const handleCancel = () => {
        setTempData(formData); // Возврат к исходным данным
        setShowEditPage(false);
    };

    return (
        <div className="profile">
            {!showEditPage ? (
                <div className="profile__body">
                    <div className="profile__header">
                        <div className="profile__title">Образование</div>
                        <div className="profile__controller" onClick={handleEditPage}>
                            <i className="fa-solid fa-pen-to-square" onClick={handleCancel}></i>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div className="profile__field">
                            <label className="profile__label label">
                                Основное образование
                                <textarea className="profile__textarea textarea" rows="5" disabled value={formData.mainEducation}></textarea>
                            </label>
                        </div>
                        <div className="profile__field">
                            <label className="profile__label label">
                                Дополнительное образование
                                <textarea className="profile__textarea textarea" rows="5" disabled value={formData.additionalEducation}></textarea>
                            </label>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="profile__body">
                    <div className="profile__header">
                        <div className="profile__title">Образование</div>
                        <div className="profile__controller" onClick={handleEditPage}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="profile__content">
                        <div className="profile__field">
                            <label className="profile__label label">
                                Основное образование
                                <textarea
                                    className="profile__textarea textarea"
                                    rows="5"
                                    name="mainEducation"
                                    value={tempData.mainEducation}
                                    onChange={sanitizeTextInput}
                                    maxLength={250}
                                ></textarea>
                            </label>
                        </div>
                        <div className="profile__field">
                            <label className="profile__label label">
                                Дополнительное образование
                                <textarea
                                    className="profile__textarea textarea"
                                    rows="5"
                                    name="additionalEducation"
                                    value={tempData.additionalEducation}
                                    onChange={sanitizeTextInput} 
                                    maxLength={250}
                                ></textarea>
                            </label>
                        </div>
                    </div>
                    <div className="profile__actions">
                        <button className="profile__button button" onClick={handleSubmit}>Сохранить изменения</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EducationPage;
